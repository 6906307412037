<template>
  <div class="max-w-[96rem] mx-auto">
    <!--TODO: recheck i18n-->
    <st-title :title="$t('studio.gnb.group_myinfo.pg_title')" class="!mt-0 !mb-[4.6rem]" />
    <st-box>
      <div class="flex flex-col gap-16">
        <dl class="flex gap-4 flex-col">
          <dt class="text-md font-medium leading-sm text-on-surface-elevation-4">
            {{ $t('studio.gnb.group_myinfo.nickname') }}
          </dt>
          <dd
            class="text-xl font-bold text-on-surface-elevation-2 leading-2xl flex gap-8 items-center"
          >
            {{ userData.nickname.length ? userData.nickname : '-' }}
            <button type="button" class="w-20 h-20" @click="showDialogChangeName()">
              <s-icon
                icon="ic-v2-community-pencil-line"
                size="3xl"
                class="text-on-surface-elevation-3 flex"
              />
            </button>
          </dd>
        </dl>
        <dl class="flex gap-4 flex-col">
          <dt class="text-md font-medium leading-sm text-on-surface-elevation-4">
            {{ $t('studio.gnb.group_myinfo.registration_time') }}
          </dt>
          <dd
            class="text-xl font-bold text-on-surface-elevation-2 leading-2xl flex gap-8 items-center"
          >
            {{ userData.date }}
          </dd>
        </dl>
      </div>
    </st-box>
    <s-tabs
      v-model="selectedTab"
      class="mt-40 stove-studio-tab"
      variant="line-flexible"
      size="sm"
      :defaultIndex="0"
      :defaultValue="GROUP_TYPE.BE_JOINED"
    >
      <s-tab-list class="gap-8">
        <s-tab-item :value="GROUP_TYPE.BE_JOINED">
          {{ $t('studio.gnb.group_myinfo.my_group') }}
        </s-tab-item>
        <s-tab-item :value="GROUP_TYPE.BE_INVITED">
          {{ $t('studio.gnb.group_myinfo.join_req_waitlist') }}
        </s-tab-item>
      </s-tab-list>
      <s-tab-panels>
        <s-tab-panel :value="GROUP_TYPE.BE_JOINED">
          <st-box class="mt-20">
            <st-table
              tableClass="mx-auto"
              :columns="myGroupColumns"
              :data="myGroups"
              :showHoverEffect="true"
              @onRowClick="navigateToGroupDetail($event.groupId)"
            >
              <template #empty>
                <st-td
                  colspan="4"
                  class="text-center py-8 h-200"
                  :class="{ 'opacity-0': isLoading }"
                >
                  {{ $t('studio.gnb.group_myinfo.my_group_none') }}
                </st-td>
              </template>
              <template #col-3>
                <st-td aLeft class="!pl-8 !pr-8 !py-0">
                  <button type="button" class="w-32 h-32 flex items-center justify-center">
                    <s-icon
                      icon="ic-v2-control-arrow-right-line"
                      size="xl"
                      class="text-on-surface-elevation-1"
                    />
                  </button>
                </st-td>
              </template>
            </st-table>
          </st-box>
        </s-tab-panel>
        <s-tab-panel :value="GROUP_TYPE.BE_INVITED">
          <st-box class="mt-20">
            <st-table tableClass="mx-auto" :columns="invitedGroupsColumns" :data="invitedGroups">
              <template #empty>
                <st-td colspan="4" class="text-center py-8 h-200">
                  {{ $t('studio.gnb.group_myinfo.join_req_none') }}
                </st-td>
              </template>
              <template #col-2="{ row: item }">
                <st-td aLeft>
                  <div class="flex flex-col justify-center min-h-[4.2rem] my-[-.7rem]">
                    <span class="text-md font-medium leading-lg">{{
                      $t(getInviteStateLabel(item.inviteStatus))
                    }}</span>
                    <span
                      v-if="item.inviteStatus === INVITE_STATE.REFUSED"
                      class="text-xs font-regular leading-xs text-on-surface-elevation-4"
                    >
                      ({{ getDateTimeByLocale(item.appliedAt) }})
                    </span>
                  </div>
                </st-td>
              </template>
            </st-table>
          </st-box>
        </s-tab-panel>
      </s-tab-panels>
    </s-tabs>

    <st-box class="mt-40">
      <h3 class="text-2xl leading-xl font-bold text-on-surface-elevation-2">
        {{ $t('studio.gnb.group_myinfo.terms_agreement') }}
      </h3>
      <div class="mt-16 flex flex-col">
        <div
          v-for="item in agreeTerms"
          :key="item.contentsNo"
          class="flex items-center gap-16 w-full border-b-1 border-solid border-border py-16 px-8"
        >
          <p class="flex-1 text-md text-on-surface-elevation-2 leading-lg font-medium">
            {{ item.title }}
            ({{ getDateTimeByLocale(item.enforcedDt, false) }})
          </p>
          <span
            v-if="item.agreeYn"
            class="shrink-0 text-md font-regular leading-lg text-on-surface-elevation-4"
          >
            <!--TODO: implement new api contract from tos, maybe it will be remove-->
            {{
              $t('studio.gnb.group_myinfo.terms_agreement.term_agree_time', {
                time: getDateTimeByLocale(item.agreeDate)
              })
            }}
          </span>
          <a
            href=""
            class="shrink-0 text-brand-primary text-md font-medium leading-md"
            target="_blank"
            @click.prevent="navigateToTermDetail(item.subCategory, item.contentsNo)"
          >
            {{ $t('studio.gnb.group_myinfo.terms_agreement.btn_view_term1') }}
          </a>
        </div>
      </div>
      <div class="mt-16">
        <h4 class="text-md leading-sm font-bold text-on-surface-elevation-2">
          {{ $t('studio.gnb.group_myinfo.terms_agreement_mkt') }}
        </h4>
        <p class="text-sm text-on-surface-elevation-4 font-regular leading-md">
          {{ $t('studio.gnb.group_myinfo.terms_agreement_mkt1') }}
        </p>
      </div>
      <div class="mt-16">
        <s-checkbox
          id="allow"
          v-model="isReceiveMarketing"
          name="allow"
          size="sm"
          align="middle"
          @change="onChangeReceiveMarketing"
        >
          <!--TODO: recheck i18n-->
          <span class="flex gap-8 items-center font-medium">
            {{ $t('studio.gnb.group_myinfo.terms_agreement_mkt_checkbox') }}
            <span class="text-xs font-regular leading-xs text-on-surface-elevation-4">
              {{ receiveMarketingText }}</span>
          </span>
        </s-checkbox>
      </div>
    </st-box>
  </div>
</template>
<script setup lang="ts">
import { cloneDeep } from 'lodash';
import { storeToRefs } from 'pinia';
import { computed, ref, shallowRef, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { type LocationQueryValue, useRoute } from 'vue-router';

import { definePageMeta } from '@/.nuxt/imports';
import { fetchMyInformationApi, updateMyInformationApi } from '@/apis/my-info.api';
import { fetchSubscriptionTermsAndConditionApi } from '@/apis/terms.api';
import StBox from '@/components/common/st-box.vue';
import StTitle from '@/components/common/st-title.vue';
import StTable from '@/components/common/table/st-table.vue';
import StTd from '@/components/common/table/st-td.vue';
import ChangeNameDialog from '@/components/my-info/change-name-dialog.vue';
import { showAlert, showDialog } from '@/composables/useDialog';
import { PLATFORM, SERVICE_ID, VIEW_AREA } from '@/constants/common.const';
import { GROUP_TYPE, INVITE_STATE } from '@/constants/my-info.const';
import { MY_INFO_GROUP_ID_PAGE_URL, TERMS_DETAIL_PAGE_URL } from '@/constants/url.const';
import { Confirmation } from '@/enums/common.enum';
import { useAppStore } from '@/stores/app.store';
import { useTermsStore } from '@/stores/terms.store';
import { useUserStore } from '@/stores/user.store';
import type {
  AgreedTermModel,
  GroupModel,
  MyInformationModel
} from '@/types/my-info/my-info-model.type';
import type { AgreedTermResponse } from '@/types/my-info/my-info-response.type';
import type { Column, ColumnSorting } from '@/types/table.type';
import type { ContentModel } from '@/types/terms/terms-model.type';
import { redirectTo } from '@/utils/common.util';
import { getDateTimeByLocale } from '@/utils/date.util';
import { clientSideSort } from '@/utils/table.util';

definePageMeta({
  title: 'My Information',
  layout: 'empty'
});

const route = useRoute();
const userStore = useUserStore();
const { userInfo } = storeToRefs(userStore);
const { t, locale } = useI18n();
const appStore = useAppStore();
const { isLoading } = storeToRefs(appStore);
const termsStore = useTermsStore();
const { firstSignUpNation, agreedTerms: terms } = storeToRefs(termsStore);

const selectedTab = ref(GROUP_TYPE.BE_JOINED);

const myInformation = ref<MyInformationModel>({
  email: userInfo.value?.myInformation?.email ?? '-', // just set data old api  before new api release
  nickname: userInfo.value?.myInformation?.nickname ?? '',
  joinedAt: userInfo.value?.myInformation?.joinedAt ?? 0,
  marketingNotificationYn: userInfo.value?.myInformation?.marketingNotificationYn ?? true,
  marketingNotificationModifiedAt:
    userInfo.value?.myInformation?.marketingNotificationModifiedAt ?? 0,
  joinedGroups: userInfo.value?.myInformation?.joinedGroups ?? [],
  invitedGroups: userInfo.value?.myInformation?.invitedGroups ?? []
});
const myGroups = ref<GroupModel[]>([]);
const invitedGroups = ref<GroupModel[]>([]);
const agreeTerms = ref<AgreedTermModel[]>([]);
const onSortMyGroups = (sortValue: number, sortKey: string) => {
  myGroups.value = clientSideSort(
    [...myInformation.value.joinedGroups],
    sortKey,
    sortValue,
    locale.value
  );
};
const onSortMyInvitedGroups = (sortValue: number, sortKey: string) => {
  invitedGroups.value = clientSideSort(
    [...myInformation.value.invitedGroups],
    sortKey,
    sortValue,
    locale.value
  );
};

const invitedGroupsColumns = ref<Column[]>([
  {
    title: 'studio.gnb.group_myinfo.join_req_group_name',
    width: 'auto',
    align: 'left',
    dataIndex: 'groupName'
  },
  {
    title: 'studio.gnb.group_myinfo.join_req_date_time',
    width: '16rem',
    align: 'left',
    render: (item: GroupModel) => getDateTimeByLocale(item.appliedAt),
    sortKey: 'appliedAt',
    onSort: ({ sortValue, sortKey }: ColumnSorting) => onSortMyInvitedGroups(sortValue, sortKey)
  },
  {
    title: 'studio.gnb.group_myinfo.join_req_status',
    width: '16rem',
    align: 'left',
    render: (item: GroupModel) => getDateTimeByLocale(item.joinedAt),
    sortKey: 'inviteStatus',
    onSort: ({ sortValue, sortKey }: ColumnSorting) => onSortMyInvitedGroups(sortValue, sortKey)
  },
  { title: '', width: '5.6rem', align: 'center' }
]);
const myGroupColumns = ref<Column[]>([
  {
    title: 'studio.gnb.group_myinfo.my_group_role_type',
    width: '16rem',
    align: 'left',
    render: (item: GroupModel) => t(getGroupRoleLabel(item.ownerYn))
  },
  {
    title: 'studio.gnb.group_myinfo.my_group_group_name',
    width: 'auto',
    align: 'left',
    dataIndex: 'groupName',
    sortKey: 'groupName',
    onSort: ({ sortValue, sortKey }: ColumnSorting) => onSortMyGroups(sortValue, sortKey)
  },
  {
    title: 'studio.gnb.group_myinfo.my_group_join_date_time',
    width: '16rem',
    align: 'left',
    render: (item: GroupModel) => getDateTimeByLocale(item.joinedAt),
    sortKey: 'joinedAt',
    onSort: ({ sortValue, sortKey }: ColumnSorting) => onSortMyGroups(sortValue, sortKey)
  },
  { title: '', width: '5.6rem', align: 'center' }
]);

const userData = computed(() => ({
  email: myInformation.value?.email ?? '-',
  nickname: myInformation.value?.nickname ?? '',
  date: getDateTimeByLocale(myInformation.value?.joinedAt)
}));

const showDialogChangeName = async () => {
  const result = (await showDialog({
    component: shallowRef(ChangeNameDialog),
    props: {
      header: 'ChangeName',
      nickname: myInformation.value.nickname
    },
    severity: 'info'
  })) as string;
  if (!result) {
    return;
  }
  myInformation.value.nickname = result;
};

const getGroupRoleLabel = (isOwner: boolean) => {
  return isOwner
    ? 'studio.gnb.group_myinfo.my_group_role_type_owner'
    : 'studio.gnb.group_myinfo.my_group_role_type_mem';
};

const isReceiveMarketing = ref(false);

const navigateToGroupDetail = (id: string) => {
  redirectTo(MY_INFO_GROUP_ID_PAGE_URL, { groupId: id });
};

// TODO: recheck i18n
const getInviteStateLabel = (state: string) => {
  switch (state) {
    case INVITE_STATE.REFUSED:
      return 'studio.gnb.grp_myinfo.join_req_stt_rejected';
    case INVITE_STATE.APPLY:
      return 'studio.gnb.group_myinfo.join_req_stt_waiting';
    default:
      return '-';
  }
};

const receiveMarketingText = computed(() => {
  return myInformation.value?.marketingNotificationYn
    ? t('studio.gnb.group_myinfo.terms_agreement_mkt_agree_time', {
      dateTime: getDateTimeByLocale(myInformation.value?.marketingNotificationModifiedAt)
    })
    : t('studio.gnb.group_myinfo.terms_agreement_mkt_reject_time', {
      dateTime: getDateTimeByLocale(myInformation.value?.marketingNotificationModifiedAt)
    });
});

const onChangeReceiveMarketing = async () => {
  try {
    const result = await updateMyInformationApi({
      marketingNotificationYn: isReceiveMarketing.value ? 'Y' : 'N'
    });
    if (result) {
      await setUpMyInformation();
      if (!isReceiveMarketing.value) {
        await showAlert({
          severity: 'info',
          content: t('studio.gnb.group_myinfo.my_group.detailed_group_mkt_uncheck_msg'),
          confirmVariant: 'blue'
        });
      } else {
        await showAlert({
          severity: 'info',
          content: t('studio.gnb.group_myinfo.my_group.detailed_group_mkt_check_msg'),
          confirmVariant: 'blue'
        });
      }
    }
  } catch (err) {
    isReceiveMarketing.value = !isReceiveMarketing.value;
    // console.error(err);
  }
};

const setUpMyInformation = async () => {
  const result = await fetchMyInformationApi();
  if (!result) {
    return;
  }
  myInformation.value = cloneDeep({
    ...result,
    marketingNotificationModifiedAt:
      result.marketingNotificationModifiedAt === 0
        ? result.joinedAt
        : result.marketingNotificationModifiedAt
  });
  isReceiveMarketing.value = myInformation.value?.marketingNotificationYn;
  myGroups.value = cloneDeep(myInformation.value.joinedGroups);
  invitedGroups.value = cloneDeep(myInformation.value.invitedGroups);
};

const setUpTermsAndCondition = async () => {
  const infos = await fetchSubscriptionTermsAndConditionApi({
    serviceId: SERVICE_ID.INDIEGAME,
    viewareaId: VIEW_AREA.STDJOIN,
    nation: firstSignUpNation.value,
    lang: locale.value,
    textYn: Confirmation.YES,
    textFormat: PLATFORM.PC
  });
  if (!infos || !infos.serviceInfos || !infos.serviceInfos[0].contentsList) {
    return;
  }

  agreeTerms.value =
    terms.value.map((item: AgreedTermResponse) => {
      const term = infos.serviceInfos[0].contentsList.find(
        (info: ContentModel) => info.termsTypeNo === item.termsNo
      );
      return {
        enforcedDt: term?.enforcedDt ?? 0,
        agreeDate: (item.updDt ?? item.regDt) ?? 0,
        title: term?.title ?? '',
        contentsNo: term?.contentsNo ?? 0,
        agreeYn: item.agreeYn === 'Y',
        subCategory: term?.subCategory ?? ''
      };
    }) || [];
};

const navigateToTermDetail = (subCategory: string, contentsNo: number) => {
  redirectTo(`${TERMS_DETAIL_PAGE_URL}?subCategory=${subCategory}&contentsNo=${contentsNo}`);
};

const init = () => {
  setUpMyInformation();
  setUpTermsAndCondition();
};

init();

watch(
  () => route.query.tab,
  (value: LocationQueryValue | LocationQueryValue[]) => {
    selectedTab.value = value ? value.toString() : GROUP_TYPE.BE_JOINED;
  },
  { immediate: true }
);
</script>
