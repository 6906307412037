<template>
  <div>
    <s-dialog :open="true" size="sm" to="stds-dialog-change-name">
      <s-dialog-overlay />
      <s-dialog-panel>
        <s-dialog-content>
          <s-dialog-content-body>
            <p class="text-xl font-bold leading-lg text-on-surface-elevation-1">
              {{ $t('studio.gnb.group_myinfo.nickname_edit1') }}
            </p>
            <p class="mt-4 text-xs leading-xs font-regular text-on-surface-elevation-4">
              {{ $t('studio.gnb.group_myinfo.nickname_edit2') }}
            </p>
            <div class="mt-8">
              <input-text
                :placeholder="$t('studio.gnb.group_myinfo.nickname_edit_place_holder')"
                name="nickname"
                maxLength="20"
                :rules="{
                  required: $t('studio.gnb.group_myinfo.nickname_edit_val_msg2'),
                  max_length: 20,
                  min_length: {
                    length: 2,
                    message: $t('studio.gnb.group_myinfo.nickname_edit_val_msg1')
                  },
                  regex: {
                    value: /^[^\s]+$/,
                    message: $t('studio.common.def_key.space_not_allowed')
                  }
                }"
              />
            </div>
          </s-dialog-content-body>
        </s-dialog-content>
        <s-dialog-footer>
          <s-button class="w-full" variant="outline" @click="onClose">
            {{ $t('studio.common.popup_case_cancel_btn') }}
          </s-button>
          <s-button class="w-full" :isDisabled="!isNickNameChanged" @click="confirm()">
            {{ $t('studio.gnb.group_myinfo.btn_nickname_edit_save') }}
          </s-button>
        </s-dialog-footer>
      </s-dialog-panel>
    </s-dialog>

    <s-portal-target name="stds-dialog-change-name" />
  </div>
</template>
<script setup lang="ts">
import { useForm } from 'vee-validate';
import { computed, toRefs } from 'vue';

import { updateMyInformationApi } from '@/apis/my-info.api';
import InputText from '@/components/validation/input-text.vue';
import { STATUS_CODE } from '@/constants/error.const';
import { RuleNames } from '@/enums/validation.enum';
import { useUserStore } from '@/stores/user.store';
import type { ErrorResponse } from '@/types/common/common.type';
import { generateErrorMsg } from '@/utils/validation.util';

const props = defineProps<{
  nickname: string;
}>();
const { nickname } = toRefs(props);

const emit = defineEmits<{
  close: [inputName?: string];
}>();

const { handleSubmit, setErrors, values } = useForm({
  initialValues: {
    nickname: nickname.value
  }
});

const isNickNameChanged = computed(
  () => props.nickname.toLowerCase() !== values.nickname.toLowerCase()
);

const { setUserNickname } = useUserStore();
const confirm = handleSubmit(async (value: { nickname: string }) => {
  try {
    const result = await updateMyInformationApi({
      nickname: value.nickname.trim()
    });
    if (!result) {
      return;
    }
    emit('close', value.nickname);
    setUserNickname(value.nickname);
    const nicknameElm = document.getElementById('gnb-nickname');
    if (nicknameElm) {
      nicknameElm.innerText = value.nickname;
    }
  } catch (err) {
    const error = err as ErrorResponse;
    const errorCode = error.statusCode ?? 0;
    if (errorCode === STATUS_CODE.STUDIO_MEMBER_NICKNAME_DUPLICATE) {
      const errorMessage = generateErrorMsg(
        'studio.gnb.group_myinfo.nickname_edit_val_msg3',
        RuleNames.CHECK_DUPLICATE
      );
      setErrors({ nickname: errorMessage });
    }
  }
});

const onClose = () => {
  emit('close');
};
</script>
